@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.checkbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 18px;
  width: 18px;
  clear: both;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-radius: 2px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #90aebb;
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #90aebb;
  /* border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity:1;
    border: 2px solid #000; */
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #3f7786;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 4px;
  top: -2px;
  width: 7px;
  height: 13px;
  border: solid #e0e0e0;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

.checkbox-label input:checked ~ .checkbox-custom.checkbox-minus::after {
  -webkit-transform: rotate(90deg) scale(1);
  transform: rotate(90deg) scale(1);
  opacity: 1;
  left: 6px;
  top: 1px;
  height: 12px;
  width: 0px;
}

.pagination-button {
  border: 1px solid #3f7786 !important;
  color: #3f7786 !important;
}

/* .MuiOutlinedInput-notchedOutline {
  border-color: #3f7786 !important;
} */
.MuiOutlinedInput-input {
  padding: 8px !important;
  /* width: 50px !important; */
  border-color: #D7D7D7 !important;
}
.MuiInputBase-root {
  border-color: #000000 !important;
  color: #000000 !important;
}
.MuiPaper-root.MuiPaper-elevation3.MuiPaper-rounded {
  overflow-x: auto !important;
}
/* Custom Select Dropdown */
.custom-select-dropdown {
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(144, 174, 187)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 5px;
  padding-right: 1.9rem !important;
  /* color: #90aebb; */
}

.sort-date-by-select {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: 15px;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(78, 78, 78)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 2px;
  color: #000000;
  height: 30px;
  width: 137px;
  padding: 0px 20px 0px 20px;
  border: 1px solid rgba(0, 109, 143, 0.5);
  border-radius: 2px;
}

/* .table-responsive table thead th:first-child {
  padding-left: 13px;
} */

/* .table-responsive table {
  font-family: "Poppins", sans-serif;
} */

.table-responsive table td {
  color: #000000;
}
.table-responsive {
  height: 86vh;
}
.table-responsive tbody tr:last-child td {
  border-bottom: 1px solid #dee2e6 !important;
}

.table-responsive table thead.sticky-top.custom-z-index {
  z-index: 4;
  background-color: #fff1eb;
}

.table-responsive table thead th .custom-column-width {
  min-width: 90px;
}

.table-responsive table thead th:first-child .custom-column-width {
  width: 20px !important;
}

.table-responsive table thead th:nth-child(2) .custom-column-width,
.table-responsive table thead th:nth-child(3) .custom-column-width {
  width: 55px !important;
}

/* Table  actions CSS */
.actions-heading {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #3f7786;
  padding-left: 15px;
}

.sort-date-by-heading {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  font-weight: normal;
  margin-right: 9px;
}

.check-icon,
.close-icon,
.arrow-dropdown-icon {
  color: #90aebb !important;
}
.check-icon:disabled,
.close-icon:disabled {
  color: #c6cfd1 !important;
}

.actions-container,
.product-videos-selected-container {
  border: 1px solid #dbe7ea;
  border-radius: 5px;
  height: 30px;
}

.product-videos-selected-container .product-videos-selected {
  color: #3f7786;
  font-size: 12px;
  line-height: 18px;
}

.separater {
  padding: 1px 5px 0px 0px;
}

.dashboardCalenderContainer .react-datepicker-wrapper {
  padding: 2px 0px 0px 1px;
}

.product-name,
.video-topic-name {
  max-width: 120px;
}

.custom-spinner {
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
}

.filter-box:focus {
  border-width: 2px !important;
}

.cursor-grab {
  cursor: grabbing;
}

.custom-date-picker {
  border-radius: 3px;
  border: 1px solid #006D8F !important;
  width: 120px;
  height: 37px;
  padding: 3px 13px;
  font-weight: 400;
}
.custom-date-picker::-webkit-input-placeholder {
  color: #90aebb;
  font-size: 11px;
}
.custom-date-picker:-ms-input-placeholder {
  color: #90aebb;
  font-size: 11px;
}
.custom-date-picker::placeholder {
  color: #90aebb;
  font-size: 11px;
}

.custom-wrapper > .react-datepicker__input-container {
  padding-right: 0;
}

.table-container {
  background-color: #f2fafd;
  border-radius: 10px;;
    padding: 0 16px 16px 16px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.tab-heading {
  font-family: "Poppins", sans-serif;
  border-bottom: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  height: 25px;
  color: #9b0c0c;
  border-color: #3f77860d;
  line-height: 18px;
  background-color: #f2fafd;
  padding: 0 16px;
  text-decoration: underline;
}

.custom-scrollbar::-webkit-scrollbar, .comments-section::-webkit-scrollbar {
  width: 8px;
}
.custom-scrollbar::-webkit-scrollbar-track, .comments-section::-webkit-scrollbar-track {
  background: #ffffff;
}
.custom-scrollbar::-webkit-scrollbar-thumb, .comments-section::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 17px;
}

.settings-menu {
  width: 200px;
}

.custom-outlined-button {
    font-family: "Mulish", sans-serif !important;
    border-radius: 10px !important;
    font-weight: 600 !important;
    border-width: 2px !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.highest-priorities {
  max-width: 200px;
  margin: 0 auto;
  background: #FFF5FB;
  /* border: 1px solid #FFABDE; */
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  padding: 8px;
}

.project-type {
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  color: #006D8F;
}

.table-container th {
  padding: .75rem;
}

.custom-label{
  max-width: 80px;
  margin: 0 auto;
  /* background: #FFF5FB; */
  border: 2px solid #aaaa;
  border-radius: 10px;
  padding: 2px 6px;
}
.custom-time-label{
  margin: 0 auto;
  /* background: #FFA292; */
  background: #f1bfa1;
  border: 2px solid #FFA292;
  border-radius: 10px;
  padding: 2px 6px;
}
.date-label{
  max-width: 120px;
  margin: 0 auto;
  /* background: #FFF5FB; */
  border: 2px solid #aaaa;
  border-radius: 10px;
  padding: 2px 6px;
}
.text-red{
  color: red;
}
.info-flag {
  height: 30px;
}
.edit-section {
  padding: 20px;
  height: calc(100vh - 260px);
  overflow-y: auto;
  padding-top: 6px;
  font-family: "Poppins", sans-serif;
  margin: 40px 0px;
}
.edit-section .edit-fileds {
  padding-bottom: 25px
}
.edit-section .edit-label, .edit-value {
  font-family: "Poppins", sans-serif;
}
.edit-section .edit-label {
  font-size: 18px;
  font-weight: 600;
}
.dialog-content .edit-dropdown {
  font-family: "Poppins", sans-serif;
  border: 1px solid #D7D7D7 !important;
  border-radius: 5px;
  padding: 8px;
  width: -webkit-fill-available
}
.dialog-content .update-button {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  color: #ffffff;
  text-transform: capitalize;
}
.edit-date-picker {
  border-radius: 5px;
  border: 1px solid #D7D7D7 !important;
  width: inherit;
  height: 37px;
  padding: 3px 13px;
  font-weight: 400;
  font-size: 16px;
}
.edit-date-picker::-webkit-input-placeholder {
  color: #D7D7D7;
  font-size: 16px;
}
.edit-date-picker:-ms-input-placeholder {
  color: #D7D7D7;
  font-size: 16px;
}
.edit-date-picker::placeholder {
  color: #D7D7D7;
  font-size: 16px;
}

.mentionTextbox__input {
  border: none;
  padding: 10px;
}
.mentions__mention {
  position: relative;
  z-index: 1;
  color: #007bff;
  pointer-events: none;
}
.mentionTextbox__highlighter {
  border: none;
  padding: 8px 0px 0px 4px
}
.mentionTextbox__input::-webkit-input-placeholder {
  color: #D7D7D7;
}
.mentionTextbox__input:-ms-input-placeholder {
  color: #D7D7D7;
}
.mentionTextbox__input::placeholder {
  color: #D7D7D7;
}
.mentionTextbox__input:focus {
  outline: none;
}

ul.mentionTextbox__suggestions__list {
  border: 1px solid #eee;
}

li.mentionTextbox__suggestions__item {
  padding: 2px 6px;
}

li.mentionTextbox__suggestions__item--focused {
  background-color: #eee;
}
.table-container .table_srNo {
  display: flex;
  padding: 0px 25px;
}
.table-container .table_srNo .table_index {
  padding: 0px 10px;
}
.cursor-pointer{
  cursor: pointer;
}
.label-img {
  padding: 1px;
  height: 22px;
}
.workcard-label {
  display: flex;
  align-items: center;
}
.pagination-summary {
  margin-top: 2px;
  padding: 12px;
  border: 1px solid #006D8F;
}

.react-date-picker__wrapper{
  border: none !important;
}
